.WideProfileContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  min-height: 100vh;
}

.WideLeftContainer {
  flex-basis: 40%;
  background-size: cover;
  display: flex;
  align-items: flex-end;
}

.WideRightContainer {
  flex-basis: 60%;
  padding: 64px 128px;
  overflow-y: scroll;
}

.WideQuoteContainer {
  width: 100%;
  background: rgba(99, 99, 99, 0.7);
  padding: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.LargeQuote {
  font-size: 100px;
  line-height: initial;
  font-style: italic;
  color: white;
}

.WideQuote {
  color: white;
  font-size: 24px;
  padding: 48px;
}

.ProfileCard {
  max-width: 650px;
  min-height: 100vh;
  border-radius: 8px;
  background: white;
  box-shadow: rgb(0 0 0 / 9%) 0px 4px 22px 0px;
}

.ProfileCardHeader {
  min-width: 650px;
  padding: 32px 24px 16px;
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;
  background: white;
}

.ProfileImage {
  display: inline-block;
  background-size: cover;
  background-position: center center;
  border-radius: 50%;
  border: 4px solid rgb(255, 255, 255);
  position: relative;
  height: 100px;
  width: 100px;
  min-height: 100px;
  min-width: 100px;
  margin-right: 16px;
}

.ProfileUser {
  color: white;
}

.ProfileUserName,
.WideProfileUserName {
  font-size: 60px;
  margin-bottom: 16px;
}

.WideProfileUserName {
  color: #333;
}

.WideProfileStatus {
  color: rgba(154, 154, 154, 0.8);
}

.ProfileStatus,
.WideProfileStatus {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 15px;
}

.ProfileStatusIndicator {
  width: 13px;
  height: 13px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(255, 255, 255);
  border-radius: 100%;
  background-color: rgb(19, 203, 110);
  box-shadow: rgb(0 0 0 / 20%) 0px 0px 3px;
  margin-right: 4px;
}

.OfflineStatus {
  background-color: rgb(104, 120, 130);
}

.ProfileBioContainer {
  background: #f8f8f8;
  padding: 32px;
  font-size: 20px;
  color: #333;
}

.AboutContainer {
  padding: 32px 120px;
  display: flex;
  flex-direction: column;
}

.WideAboutContainer {
  justify-content: left;
  padding: 8px 24px;
}

.AboutSection {
  display: flex;
  align-items: center;
  color: #333;
  font-size: 14px;
  padding: 8px 0;
}

.MainButtonBar {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  margin: 16px 0;
}

.MainButton {
  margin-right: 8px;
  border-style: solid;
  flex-grow: 1;
  background: transparent;
}

.SocialMediaBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin: 16px 32px;
}

.SocialMediaBar > a {
  color: currentColor;
  opacity: 0.5;
  transition: opacity 0.4s;
}

.SocialMediaBar > a:hover {
  opacity: 1;
}

.DetailsH2 {
  text-transform: uppercase;
  font-size: 14px;
  color: #999;
  margin-top: 32px;
  margin-bottom: 0;
  line-height: 1.25;
  font-weight: 400;
}

.DetailsBio {
  margin-top: 16px;
  font-size: 14px;
  color: #333333;
}

.DetailsContent {
  color: #333333;
  margin: 0;
  padding: 8px 0;
  font-size: 14px;
}

.ErrorContainer {
  padding: 32px 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #5d6a74;
}

@media (max-width: 800px) {
  .ProfileCard {
    width: 100%;
    min-width: 100%;
    border-radius: 0;
  }
  .ProfileCardHeader {
    width: 100%;
    border-radius: 0;
    padding: 32px 16px;
    justify-content: center;
    min-width: 0;
  }
  .ProfileUserName,
  .WideProfileUserName {
    font-size: 30px;
    margin-bottom: 16px;
  }
  .ProfileImage {
    height: 75px;
    width: 75px;
    min-height: 75px;
    min-width: 75px;
    border-width: 4px;
  }
  .AboutContainer {
    padding: 16px;
  }
  .MainButtonBar {
    flex-direction: column;
    row-gap: 8px;
  }
  .WideProfileContainer {
    flex-direction: column;
    min-height: 100vh;
    height: fit-content;
  }
  .WideLeftContainer {
    flex-basis: 0;
  }
  .WideRightContainer {
    padding: 16px;
  }
  .WideQuoteContainer {
    padding: 12px;
  }
  .WideQuote {
    padding: 12px;
    font-size: 18px;
  }
}
