.container {
  min-height: 100vh;
  padding-top: 4rem;
  padding-bottom: 4rem;
  width: 100%;
  min-height: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex: 1 1 auto;
  min-width: 0;
  display: flex;
  overflow-y: scroll;
}

.wideContainer {
  padding: 0;
}

@media (max-width: 800px) {
  .container {
    padding-top: 16px;
    overflow-x: hidden;
  }
}
