.CircleLoader {
  position: relative;
  width: 32px;
  height: 32px;
}

.CircleLoaderSpinner {
  animation: rotate 2s linear infinite;
  transform-origin: center center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.CircleLoaderCircle {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-width: 10%;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes color {
  100%,
  0%,
  40%,
  66%,
  80%,
  90% {
    stroke: #94c8ff;
  }
}
