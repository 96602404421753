.BannerContainer {
  width: 100vw;
  height: 48px;
  position: absolute;
  background: white;
  display: flex;
  align-items: center;
  padding: 0 16px;
  box-shadow: 0 1px 2px 0 rgba(70, 82, 89, 0.24);
  justify-content: space-between;
  z-index: 100;
}

.BannerContainerLeft {
  display: flex;
  align-items: center;
}

@media (max-width: 800px) {
  .BannerText {
    display: none;
  }
}

.BannerText {
  font-size: 14px;
  margin-left: 16px;
  color: rgb(53, 63, 69);
}

.BannerButton {
  border: 1px solid rgb(193, 204, 210);
  background-color: rgb(245, 248, 250);
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  font-family: 'ProximaNova-Semibold';
  transition: all 0.3s;
  margin-left: 16px;
}

.BannerByDrift {
  margin-left: auto;
  color: #157afb;
  text-decoration: none;
  font-size: 14px;
}
